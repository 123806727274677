
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { getDate } from 'date-fns';


const initialState = {
    savedSearches: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchSavedSearches = createAsyncThunk('savedSearches/fetchSavedSearches', async (data) => {
    try {
        const response = await axios.post('api/SavedSearch/GetSavedSearches', data, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
        //  response.data.sort((a, b) => (a.presentationName > b.presentationName) ? 1 : -1)      
        return [...response.data];
    } catch (err) {
        return err.message;
    }
})


export const createSavedSearch = createAsyncThunk('savedSearches/create',
    async (data, { rejectWithValue }) => {
        try {

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.post('api/SavedSearch/PostSavedSearch', JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

export const updateSavedSearchName = createAsyncThunk('savedSearches/updateSearchName',
    async (data, { rejectWithValue }) => {
        try {

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.post('api/SavedSearch/UpdateSavedSearch', JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

const savedSearchesSlice = createSlice({
    name: "savedSearches",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.savedSearches = payload;
        },

    },
    extraReducers(builder) {
        builder
            .addCase(fetchSavedSearches.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchSavedSearches.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.savedSearches = payload
            })
            .addCase(fetchSavedSearches.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectSavedSearches = (state) => state.savedSearches.savedSearches;

export const { setList } = savedSearchesSlice.actions

export default savedSearchesSlice.reducer
import React from 'react';
import { Typography, Grid } from '@mui/material';
import SplashSwoosh from './SplashSwoosh';
import styled from '@emotion/styled';
import logo from '../../assets/images/griffith-logo.png';
import hero from '../../assets/images/splash-hero-right.png';

export default function SplashHero() {
  const Container = styled.div(props => ({
  // //  background: '#fafafa top left url("../../assets/images/bg-noise-pattern.png")'
  background: '#fafafa'
  }))

  
  return (
    <main>
      <Container maxWidth="xl" disableGutters>
        <Grid container>
          <Grid item xs={1}>&nbsp;
          </Grid>
          <Grid item xs={6}>
            <div className="hero">
              <img src={logo} alt="Griffith Foods" className='hero--logo' />
              <Typography variant="h1" className='hero--headline' 
                sx={{ mt: 5, 
                      mb: 2
                    }}>
                <em>The world’s latest menu items at your finger tips.</em>
              </Typography>
              <SplashSwoosh />
              <br/>
              <Typography variant="body2" sx={{ mt: 3 }} className="hero--desc">Our thoughtful, insights-driven Menu Intelligence App delivers the latest information around 
              top global chain restaurant limited time offers. See what’s trending in your market and around the world. — It’s a true birds-eye view into what’s cooking now, and tomorrow.</Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <img src={hero} alt="hero" className="hero--right" />           
          </Grid>
        </Grid>
        

      </Container>
    </main>
  )
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
}

export const navbarSlice = createSlice({
    name: "navbar",
    initialState: initialState,
    reducers: {
        setActiveNav: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { setActiveNav } = navbarSlice.actions

export default navbarSlice.reducer
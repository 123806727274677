import { configureStore } from '@reduxjs/toolkit'
import navbarReducer from './navbarSlice'
import importReportReducer from './importReportSlice'
import countriesReducer from './countriesSlice'
import reportMonthYearReducer from './reportMonthYearSlice'
import csvReportReducer from './csvReportSlice'
import daypartsReducer from './daypartsSlice'
import mealpartsReducer from './mealpartsSlice'
import brandsReducer from './brandsSlice'
import menugroupsReducer from './browseMenuItemsSlice'
import menuimportsReducer from './statusTableSlice'
import presentationsReducer from './presentationsSlice'
import savedSearchesReducer from './savedSearchesSlice'
import userReducer from './userSlice'

//import logger  from 'redux-logger'

export const store = configureStore({
    reducer: {
        navbar: navbarReducer,
        importReport: importReportReducer,
        countries: countriesReducer,
        reportMonthYear: reportMonthYearReducer,  
        csvReport: csvReportReducer, 
        dayparts: daypartsReducer, 
        mealparts: mealpartsReducer,
        brands: brandsReducer,
        menugroups: menugroupsReducer,
        menuimports: menuimportsReducer,
        presentations: presentationsReducer,
        savedSearches: savedSearchesReducer,
        user: userReducer,
    }
})

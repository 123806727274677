import React from 'react'
import SplashHero from '../components/splash-landing/SplashHero'
import SplashNavigation from '../components/splash-landing/SplashNavigation'
import Typography from '@mui/material/Typography'
import Footer from '../components/footer/Footer'
import '../components/splash-landing/Splash.css';

export default function Splash()  {
    return ( 
      <>
      <SplashHero />
      <Typography variant="h2" 
        sx={{ mt: 5, 
              mb: 5, 
              textAlign: 'center' 
            }}>
       <em>What would you like to do?</em></Typography>
       <SplashNavigation />  
       <Footer />  
      </>
    )
  }



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    dayparts: [],
    daypartOptions: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    selectedDaypart: '',
}

export const fetchDayparts = createAsyncThunk('dayparts/fetchDayparts', async () => {
    try {
        const response = await axios.get('api/dayparts')      
        response.data.sort((a, b) => (a.dayPartName > b.dayPartName) ? 1 : -1)     
        return [...response.data];
    } catch (err) {
        return err.message;
    }
})

const daypartsSlice = createSlice({
    name: "dayparts",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.dayparts = payload;
        },
        setSelectedDaypart: (state, { payload }) => {
             state.selectedDaypart = payload
         },
         getDaypartByName: (state, { payload }) => {
            state.dayparts.find(d => d.dayPartName === payload.dayPartName)
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDayparts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchDayparts.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.selectedDaypart = ''
                state.dayparts = payload                               
                state.dayparts.map((daypart) => {
                    state.daypartOptions.push(daypart.dayPartName);                    
                });
            })
            .addCase(fetchDayparts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const getAllDayparts = (state) => state.dayparts.dayparts;
export const getDaypartsStatus = (state) => state.dayparts.status;
export const getDaypartsError = (state) => state.dayparts.error;
export const getSelectedDaypart = (state) => state.dayparts.selectedDaypart;
export const getDayPartOptions = (state) => state.dayparts.daypartOptions;

export const { getDaypartByName, setList, setSelectedDaypart } = daypartsSlice.actions

export default daypartsSlice.reducer
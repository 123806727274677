import React from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
    useGridApiRef,
    DataGridPro
} from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { XDATA_GRID_PRO_LICENSE } from '../../shared/Constants'
import { useSelector, useDispatch } from 'react-redux'
import { getDbHeaders, mapDbHeaders, getHeaderRow } from '../../../store/csvReportSlice'

LicenseInfo.setLicenseKey(
    XDATA_GRID_PRO_LICENSE
)

export default function HeaderMapper() {

    const [editRowsModel, setEditRowsModel] = React.useState({});
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const dispatch = useDispatch();
    const dbHeaders = useSelector(getDbHeaders);
    const csvOptions = useSelector(getHeaderRow);

    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const apiRef = useGridApiRef();

    const handleCellClick = React.useCallback(
        (params) => {
          if(params.isEditable) apiRef.current.setCellMode(params.id, params.field, "edit");
        },
        [apiRef]
      );

      React.useEffect(() => {
        return apiRef.current.subscribeEvent(
          "cellModeChange",
          (params, event) => {
            event.defaultMuiPrevented = true;
          },
          { isFirst: true }
        );
      }, []);
    

    const handleRowEditCommit = React.useCallback(
        async (id) => {
            const model = apiRef.current.getEditRowsModel();
            const newRow = model[id];
            const mappedCSVField = newRow.mappedCSVField.value;
            const oldRow = apiRef.current.getRow(id);
            
           try {   
                dispatch(
                    mapDbHeaders({ id: id, mappedCSVField: mappedCSVField })
                  );             
            } catch (error) {
                console.log(error);
                apiRef.current.updateRows([oldRow]);
                setSnackbar({ children: 'Error while saving record. See log for details.', severity: 'error' });
            }
        },
        [apiRef],
    );

    const columns = [
        {
            field: 'dbField',
            headerName: 'Database Column Name',
            minWidth: 220,
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            headerClassName: 'grid-theme--header',
        },
        {
            field: 'mappedCSVField',
            headerName: 'CSV Column Name',
            minWidth: 220,
            editable: true,
            type: 'singleSelect',
            disableColumnMenu: true,
            sortable: false,
            headerClassName: 'grid-theme--header',
            preProcessEditCellProps: (params) => {   
                const isValid = params.props.value !== '--------- Select ---------' ? true : false;
                return { ...params.props, error: !isValid };
              },
           valueOptions: ['--------- Select ---------',  ...csvOptions]
        },        
     
    ];

    return (
        <>           
            <Box
                sx={{
                    width: '100%',

                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        color: '#000000',
                        fontWeight: 600,
                        fontSize: '14px'
                    },
                    '& .MuiDataGrid-cell--editing': {
                        '& .MuiInputBase-root': {
                            height: '100%',
                        },
                    },
                    '& .Mui-error': {
                        bgcolor: (theme) =>
                            `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
                    },
                    '& .MuiGrid-root.MuiGrid-container': {
                        justifyContent: 'center',
                    },
                    '& .cell-bg.needtoedit': {
                        backgroundColor: '#ecd291',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#fafafa',
                    },
                    '& .grid-theme--header': {
                        backgroundColor: '#fafafa',
                    }
                }}
            >

                <DataGridPro
                    sx={{
                        height: 520,
                    }}
                    rows={dbHeaders}
                    editMode="row"
                    columns={columns}
                    apiRef={apiRef}
                    onRowEditCommit={handleRowEditCommit}
                    disableColumnPinning={true}
                    hideFooter={true}
                    hideFooterPagination={true}
                    hideFooterRowCount={true}
                    hideFooterSelectedRowCount={true}
                    loading={dbHeaders.length === 0}
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    onCellClick={handleCellClick}
                />
                {!!snackbar && (
                    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </Box>
        </>
    );
}

import React from 'react';
import { Typography, Grid } from '@mui/material';
import SplashSwoosh from './SplashSwoosh';
import styled from '@emotion/styled';
import logo from '../../assets/images/griffith-logo.png';
import hero from '../../assets/images/global-splash-hero-right.png';

export default function GlobalSplashHero() {
  const Container = styled.div(props => ({
     background: '#fafafa'
  }))

  
  return (
    <main>
      <Container maxWidth="xl" disableGutters>
        <Grid container>
          <Grid item xs={1}>&nbsp;
          </Grid>
          <Grid item xs={6}>
            <div className="hero">
              <img src={logo} alt="Griffith Foods" className='hero--logo' />
              <Typography variant="h1" className='hero--headline' 
                sx={{ mt: 5, 
                      mb: 2
                    }}>
                <em>Welcome to our <br/>Culinary Resource Center</em>
              </Typography>
              <SplashSwoosh />
              <br/>
              <Typography variant="body2" sx={{ mt: 3 }} className="hero--desc">Whether you’re looking for latest information around Quick-Serve-Restaurants LTO menu items or creating insights-driven culinary concepts, you’ve come to right place. Choose your path below.</Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <img src={hero} alt="hero" className="hero--right" />           
          </Grid>
        </Grid>
        

      </Container>
    </main>
  )
}

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import browseIcon from "../../assets/images/browse-icon.png";
import presentationIcon from "../../assets/images/presentation-icon.png";
import savedSearchIcon from "../../assets/images/saved-searches-icon.png";
import { NavLink } from 'react-router-dom'

export default function SplashNavigation() {
  

  return (
    <div className="splash-cards">
  <NavLink to="/browse-menu-items" style={{ textDecoration: 'none' }}>
    <Card sx={{ mb: 3 }} className="splash-card"
    >
      <CardActionArea className="splash-card--action-area">
        <CardMedia
          component="img"
          height="60"
          image={browseIcon}
          alt="browse"
          sx={{ 
            ml: 'auto', 
            mr: 'auto', 
            mt: 4, 
            width: '67px' 
          }} />
        <CardContent>
          <Typography gutterBottom variant="h3" component="div">
          <em>Browse Menu Items</em>
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Explore what the world’s<br/> most famous chain restaurants are serving up.
          </Typography>
          <KeyboardArrowDownIcon 
            color="primary"
            aria-label="arrow"          
            sx={{ 
              mt: 4, 
              mb: 2, 
              fontSize: "2.4rem", 
              "&:hover": { fill: "#8dc63f" } 
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
</NavLink>
<NavLink to="/presentations" style={{ textDecoration: 'none' }}>
    <Card sx={{ mb: 3 }} className="splash-card">
      <CardActionArea className="splash-card--action-area">
        <CardMedia
          component="img"
          height="60"
          image={presentationIcon}
          alt="browse"
          sx={{ 
            ml: 'auto', 
            mr: 'auto', 
            mt: 4, 
            width: '60px' 
          }} />
        <CardContent>
          <Typography gutterBottom variant="h3" component="div">
          <em>My Presentations</em>
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Welcome back!<br /> Here you’ll find the latest presentations you created.
          </Typography>
          <KeyboardArrowDownIcon 
            color="primary"
            aria-label="arrow"          
            sx={{ 
              mt: 4, 
              mb: 2, 
              fontSize: "2.4rem", 
              "&:hover": { fill: "#8dc63f" } 
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
</NavLink>
<NavLink to="/saved-searches" style={{ textDecoration: 'none' }}>
    <Card sx={{ mb: 3 }} className="splash-card">
      <CardActionArea className="splash-card--action-area">
        <CardMedia
          component="img"
          height="60"
          image={savedSearchIcon}
          alt="browse"
          sx={{ 
            ml: 'auto', 
            mr: 'auto', 
            mt: 4, 
            width: '63px' 
          }} />
        <CardContent>
          <Typography gutterBottom variant="h3" component="div">
          <em>Saved Searches</em>
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Make quick work of reocurring querries by simply saving your searches as you go.
          </Typography>
          <KeyboardArrowDownIcon 
            color="primary"
            aria-label="arrow"          
            sx={{ 
              mt: 4, 
              mb: 2, 
              fontSize: "2.4rem", 
              "&:hover": { fill: "#8dc63f" } 
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
</NavLink>
     </div>
  );
}

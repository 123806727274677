import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import "../../../pages/admin/ImportReport.css"
import Container from '@mui/material/Container'
import { next, restart } from '../../../store/importReportSlice'
import MenuItemsMapper from './MenuItemsMapper'
import { useDispatch, useSelector } from 'react-redux'
import { 
  resetReport, 
  prepMenuItemsForReview
} from '../../../store/csvReportSlice'
import { getMonthYear } from '../../../store/reportMonthYearSlice'
import { getSelectedCountry } from '../../../store/countriesSlice'


const ThirdStep = () => {
  const dispatch = useDispatch();

  const selectedcountry = useSelector(getSelectedCountry)
  const reportDate = useSelector(getMonthYear)

  var isButtonDisabled = false

  const handleNext = () => {
    isButtonDisabled = true
    dispatch(prepMenuItemsForReview({selectedcountry, reportDate}))
    dispatch(next())
  }

  const handleCancel = () => {
    dispatch(resetReport())
    dispatch(restart())
  }

  return (

    <Container maxWidth="xl">
      <Grid container spacing={3} >
        <>
          <Grid item xs={12}>
            <Box sx={{ minWidth: 442 }}>
              <MenuItemsMapper />
            </Box>
          </Grid>
        </>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 12 }}>
      <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}          
          color="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          disabled={isButtonDisabled}
          color="primary"
          // onClick={!isError() ? handleNext : () => null}
          onClick={handleNext}
        >
          Next
        </Button>
        
      </Box>
    </Container>
  )
}
export default ThirdStep
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import "./ImportReport.css";
import FirstStep from '../../components/admin/import-report/FirstStep'
import SecondStep from '../../components/admin/import-report/SecondStep'
import ThirdStep from '../../components/admin/import-report/ThirdStep'
import Confirm from '../../components/admin/import-report/Confirm'
import Success from '../../components/admin/import-report/Success'
import Container from '@mui/material/Container'
import { getActiveStep } from '../../store/importReportSlice'
import { useSelector } from 'react-redux'


const labels = ['Upload Report File', 'Map Header Row', 'Map Filters', 'Confirmation']

const handleSteps = (step) => {

    switch (step) {
        case 0:
            console.log("step 1")
            return <FirstStep />
        case 1:
            console.log("step 2")
            return <SecondStep />
        case 2:
            console.log("step 3")
            return <ThirdStep />
        case 3:
            console.log("step 4")
            return <Confirm />
        default:
            console.log("step: " + step)
            throw new Error('Unknown step')
        
            
    }
}


const ImportReport = () => {

    const activeStep = useSelector(getActiveStep)

    return (
        <Container>

            {activeStep === labels.length ? (
                <Success />
            ) : (
                <>
                    <Box sx={{ my: 4 }}>
                        <Typography variant="h4" align="center">
                            Import Menu Items
                        </Typography>
                    </Box>
                    <Stepper activeStep={activeStep} sx={{ py: 2 }} className="stepper" alternativeLabel>
                        {labels.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {handleSteps(activeStep)}
                </>

            )}

        </Container>

    );
}
export default ImportReport
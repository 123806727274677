import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import menuIntelImg from "../../assets/images/global-splash-mi-card.png";
import conceptLockerImg from "../../assets/images/global-splash-cl-card.png";
import { NavLink } from 'react-router-dom'

export default function GlobalSplashNavigation() {
  

  return (
    <div className="splash-cards">
  <NavLink to="/dashboard" style={{ textDecoration: 'none' }}>
    <Card sx={{ mb: 3, mt: 4 }} className="splash-card"
    >
      <CardActionArea className="splash-card--action-area">
        <CardMedia
          component="img"          
          image={menuIntelImg}
          alt="browse"
          sx={{ 
            ml: 'auto', 
            mr: 'auto',           
          }} />
        <CardContent>
          <Typography gutterBottom variant="h3" component="div" style={{color: '#007044'}}>
          <em>Menu Intelligence Tool</em>
          </Typography>
          <Typography variant="body2" color="text.secondary">
          See what's trending on chain restaurants in your market and around the world - it's a true birds-eye view into what's cooking now, and tomorrow.
          </Typography>
          <KeyboardArrowDownIcon 
            color="primary"
            aria-label="arrow"          
            sx={{ 
              mt: 4, 
              mb: 2, 
              fontSize: "2.4rem", 
              "&:hover": { fill: "#8dc63f" } 
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
</NavLink>
<a href="https://conceptlocker.miron.services" style={{ textDecoration: 'none' }}>
    <Card sx={{ mb: 3, mt: 4 }} className="splash-card">
      <CardActionArea className="splash-card--action-area">
        <CardMedia
          component="img"        
          image={conceptLockerImg}
          alt="browse"
          sx={{ 
            ml: 'auto', 
            mr: 'auto',            
          }} />
        <CardContent>
          <Typography gutterBottom variant="h3" component="div" style={{color: '#ff9300'}}>
          <em>Culinary Concept Locker</em>
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Griffith Foods culinary staff turn ingredients into culinary inspiration, based on real-life insights.
          </Typography>
          <KeyboardArrowDownIcon 
            color="primary"
            aria-label="arrow"          
            sx={{ 
              mt: 4, 
              mb: 2, 
              fontSize: "2.4rem", 
              fill: '#ff9300',
              "&:hover": { fill: "#8dc63f" } 
            }}
          />
          
        </CardContent>
      </CardActionArea>
    </Card>
</a>
     </div>
  );
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    monthYearStr: '',
}

const reportMonthYearSlice = createSlice({
    name: "reportMonthYear",
    initialState,
    reducers: {        
        setMonthYear: (state, {payload}) => {
            state.monthYearStr = payload 
        },   
    }
})


export const getMonthYear = (state) => state.reportMonthYear.monthYearStr;

export const { setMonthYear } = reportMonthYearSlice.actions

export default reportMonthYearSlice.reducer
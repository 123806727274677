import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { format } from 'date-fns'
import { unwrapResult } from '@reduxjs/toolkit'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
  getMenuImportsStatus,
  getMenuImportsError,
  fetchStatusTable,
  selectAllMenuImports,
  updateStatus,
  rejectImportReport
} from '../../../store/statusTableSlice'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function Status() {

  const dispatch = useDispatch();

  const rows = useSelector(selectAllMenuImports)
  const menuimportsStatus = useSelector(getMenuImportsStatus);
  const menuimportsError = useSelector(getMenuImportsError);


  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    if (menuimportsStatus === 'idle') {
      dispatch(fetchStatusTable())
    }
  }, [menuimportsStatus, dispatch])

  const handleUpdateStatus = report => event => {    
    dispatch(updateStatus(report))

      .then(unwrapResult).then(originalPromiseResult => {
        setSnackbar({ children: 'Report record successfully updated', severity: 'success' });
        dispatch(fetchStatusTable())
      })
      .catch(rejectedValueOrSerializedError => {
        setSnackbar({ children: `An error has occured updating record. Check log for details.`, severity: 'error' });
      })
  }
  

  const handleRejectReport = (id) => event => {    
    dispatch(rejectImportReport(id))    
      .then(unwrapResult).then(originalPromiseResult => {
        dispatch(fetchStatusTable())
        setSnackbar({ children: 'Report successfully deleted', severity: 'success' });
      })
      .catch(rejectedValueOrSerializedError => {
        setSnackbar({ children: `An error has occured deleting record. Check log for details.`, severity: 'error' });
      })
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>File</StyledTableCell>
              <StyledTableCell>Review</StyledTableCell>
              <StyledTableCell>User</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell align="center">Records</StyledTableCell>
              <StyledTableCell align="center">Errors</StyledTableCell>
              <StyledTableCell align="center">Approve</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell><a href="#">{row.csvFileName}</a></StyledTableCell>
                <StyledTableCell><a href={"/admin/review-report/" + row.menuGroup} target="_blank">Review</a></StyledTableCell>
                <StyledTableCell>{row.adminUserEmail}</StyledTableCell>
                <StyledTableCell>{format(new Date(row.importDate), 'MM/dd/yyyy hh:m bbbb')}</StyledTableCell>
                <StyledTableCell>{row.statusCode}</StyledTableCell>
                <StyledTableCell align="center">{row.recordsCount}</StyledTableCell>
                <StyledTableCell align="center">{row.errorCount}</StyledTableCell>
                <StyledTableCell align="center"><CheckCircleOutlineIcon onClick={handleUpdateStatus(row)} sx={{ cursor: 'pointer', width: '0.8em', color: '#007044' }} /></StyledTableCell>
                <StyledTableCell align="center"><HighlightOffIcon onClick={handleRejectReport(row.id)} sx={{ cursor: 'pointer', width: '0.8em', }} /></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}

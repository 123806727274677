
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


const initialState = {
    menuimports: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchStatusTable = createAsyncThunk('menuImport/fetchStatusTable', async () => {
    try {
        
        const response = await axios.get('api/menuimports')      
        response.data.sort((a, b) => (a.importDate > b.importDate) ? 1 : -1)      
        return [...response.data];
    } catch (err) {
        return err.message;
    }
})

export const updateStatus = createAsyncThunk('menuImport/updateStatus', async (menuImport, { rejectWithValue })  => {
    try {
      
       const headers = {
          "Accept": "application/json",
           "Content-Type": "application/json",
          //    "X-CSRF-TOKEN": "e7lwcn_OBGJuu2QsIA8auXzsvi9RGlzueRGDDwVsSKU"
       };
 
       const response = await axios.put('api/menuimports/UpdateMenuImport', menuImport,
       {headers})
       
       return response.data;
    } catch (err) {
       console.log("err: " + JSON.stringify(err.message))
       return rejectWithValue([], err);
    }})

    export const rejectImportReport = createAsyncThunk('menuImport/rejectImportReport'
    , async (id, { rejectWithValue })  => {
        try {          
           const headers = {
              "Accept": "application/json",
               "Content-Type": "application/json",
           };
           const apiUrl = 'api/menuimports/' + id
           const response = await axios.delete(apiUrl, id,
           {headers})
           
           return response.data;
        } catch (err) {
           console.log("err: " + JSON.stringify(err.message))
           return rejectWithValue([], err);
        }})

const statusTableSlice = createSlice({
    name: "menuimports",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.menuimports = payload;
        },
        
    },
    extraReducers(builder) {
        builder
            .addCase(fetchStatusTable.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchStatusTable.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
               
                state.menuimports = payload               
                    
            })
            .addCase(fetchStatusTable.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllMenuImports = (state) => state.menuimports.menuimports;
export const getMenuImportsStatus = (state) => state.menuimports.status;
export const getMenuImportsError = (state) => state.menuimports.error;

export const { setList } = statusTableSlice.actions

export default statusTableSlice.reducer
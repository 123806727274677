import React, { useEffect, useState } from "react"
import theme from "./theme"
import Splash from "./pages/Splash"
import GlobalSplash from "./pages/GlobalSplash"
import ManageMenuFilters from "./pages/admin/ManageMenuFilters"
import DashboardLayout from "./Layouts/DashboardLayout"
import BrowseMenuItems from "./pages/BrowseMenuItems"
import Presentations from "./pages/Presentations"
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Route, Routes } from "react-router-dom"
import ImportReport from "./pages/admin/ImportReport"
//import Test from "./Test"
import ReviewReport from "./pages/admin/ReviewReport"
import SavedSearches from "./pages/SavedSearches"
import ScrollToTop from "./ScrollToTop"
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { getUser, getUserStatus, getUserError, fetchAuthUser } from './store/userSlice'
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid'

export default function App() {
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => { // Anything except 2XX goes to here
        const status = error.response?.status || 500;
        console.log("status:" + status)
        if (status === 401) {
            window.location = window.location.protocol + "//" + window.location.host + "/auth"
            setLoading = true;
            setLoggedIn = false;
        } else {
            return Promise.reject(error);
        }
    });


    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const userStatus = useSelector(getUserStatus);
    const error = useSelector(getUserError);

    useEffect(() => {
        if (userStatus === 'idle') {
            dispatch(fetchAuthUser())    
                  
        }
    }, [userStatus, dispatch])


    const checkAuth = () => {
        console.log("userStatus !!" + userStatus)
        if (userStatus === 'suceeded') {
            setLoading(false);
            setLoggedIn(true);
        } else if (userStatus === 'failed') {
            setLoading(true);
            setLoggedIn(false);
            window.location = window.location.protocol + "//" + window.location.host + "/auth"
        }
    }


    if (userStatus !== "succeeded") {
        
        return (
            <>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100vh' }}
>
                        <CircularProgress color="success" />
                    </Grid>
                </ThemeProvider>
            </>
        )

    }

   
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ScrollToTop />
                <Routes>
                    <Route element={<DashboardLayout />}>
                        <Route path="/admin/import-report" element={<ImportReport />} />
                        <Route path="/admin/manage-menu-filters" element={<ManageMenuFilters />} />
                        <Route path="/admin/review-report/:groupId" element={<ReviewReport />} />
                        <Route path="/browse-menu-items" element={<BrowseMenuItems />} />
                        <Route path="/presentations" element={<Presentations />} />
                        <Route path="/saved-searches" element={<SavedSearches />} />
                    </Route>
                    <Route path="/" element={<GlobalSplash />} />
                    <Route path="/dashboard" element={<Splash />} />
                </Routes>
            </ThemeProvider>
        </>
    )
}
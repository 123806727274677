import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    hasError: false,
    activeStep: 0
}

export const importReportSlice = createSlice({
    name: "importReport",
    initialState: initialState,
    reducers: {
        next: state => {
            state.activeStep += 1           
        },
        back: state => {
            state.activeStep -= 1
        },
        restart: state => {
            state.activeStep = 0
        },
    }
})

export const getActiveStep = (state) => state.importReport.activeStep;

export const { next, back, restart } = importReportSlice.actions

export default importReportSlice.reducer
import { createTheme } from '@mui/material/styles';

const primaryMain = '#007044'
const secondaryMain = '#a0c800'
const formFieldMain = '#666666'

const theme = createTheme({
  palette: {
    primary: {
      main: primaryMain
    },
    secondary: {
      main: secondaryMain,
      contrastText: '#ffffff',
    },
    formfield: {
      main: formFieldMain,
      contrastText: '#ffffff',
        },
        neutral: {
            main: '#ffffff',
            contrastText: primaryMain
        },
    tonalOffset: 0.1, 
  },
  typography: {
    fontFamily: [
      'trasandina',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: [
        'trasandina',
        'sans-serif',
      ].join(','),
      fontWeight: 700,
      fontSize: 52,
      lineHeight: '1.1em',
      color: primaryMain,
    },
    h2: {
      fontFamily: [
        'trasandina',
        'sans-serif',
      ].join(','),
      fontWeight: 600,
      fontSize: 40,
      lineHeight: '1.2em',
      color: primaryMain,
    },
    h3: {
      fontFamily: [
        'trasandina',
        'sans-serif',
      ].join(','),
      fontWeight: 600,
      fontSize: 23,
      lineHeight: '1.2em',
      color: '#464646',
    },
    body1: {
      fontFamily: [
        'trasandina',
        'sans-serif',
      ].join(','),
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '1.4em',
      color: primaryMain,
    },
    body2: {
      fontFamily: [
        'Source Sans Pro',
        'sans-serif',
      ].join(','),
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '1.5em',
      color: '#464646 !important',
      },
      body3: {
          fontFamily: [
              'Source Sans Pro',
              'sans-serif',
          ].join(','),
          fontWeight: 400,
          fontSize: 15,
          lineHeight: '1.5em',
          color: primaryMain +' !important',
      },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiInputLabel: { // Name of the component ⚛️ / style sheet
      styleOverrides: {
        root: { // Name of the rule
          color: '#464646',
          fontSize: 15,
        //  transform: 'translate(14px, 8px) scale(1)',
          "&.Mui-focused": { // increase the specificity for the pseudo class
            color: formFieldMain,
            fontSize: 15,
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: { 
          width: '100%',
          marginLeft: 0,
        "&.container": {
          width: '100%',
        }
      }
      }
     
    },
    MuiOutlinedInput: { // Name of the component ⚛️ / style sheet
      
      styleOverrides: {
        
        root: { // Name of the rule
          color: '#464646',
          fontFamily: [
            'Source Sans Pro',
            'sans-serif',
          ].join(','),
          fontSize: 15,
          "&.Mui-focused": { // increase the specificity for the pseudo class
            color: formFieldMain,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: formFieldMain,
              borderWidth: '1px'
            },            
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: formFieldMain,
              },
          '& .MuiMenu': {         
            boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 1px 4px 0px rgb(0 0 0 / 14%), 0px 1px 1px 1px rgb(0 0 0 / 12%)'
          },
          '& .MuiSelect-select': {
            color: '#464646',
            fontSize: 15,
          },
          '& .MuiOutlinedInput-input': {
            color: '#464646',
            fontSize: 15,
          },
          

        }
      }
    },
    MuiMenuItem: {  
      styleOverrides: {
        root: {  
          color: '#464646', 
          fontSize: 15,
          fontFamily: [
            'Source Sans Pro',
            'sans-serif',
          ].join(','),
          '&.Mui-selected': {
            backgroundColor: 'rgba(102, 102, 102, 0.12)'
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(94, 94, 94, 0.12)',
          }
        }, 
      }   
    },
    MuiCalendarPicker: {  
      styleOverrides: {
        root: {  
          fontFamily: [
            'Source Sans Pro',
            'sans-serif',
          ].join(','),
          '& .PrivatePickersFadeTransitionGroup': {
            color: '#464646',
            fontSize: 15
          },
          
        }, 
      }   
    },
    MuiMonthPicker: {  
      styleOverrides: {
        root: {  
          '& MuiTypography:disabled': {
            color: '#e3e0e0',
            //not working
          },          
        }, 
      }   
    },
    MuiButton: {  
      styleOverrides: {
        root: {  
          textTransform: 'capitalize',    
          fontStyle: 'italic',  
        }, 
      }   
    },
    MuiButton: {  
      styleOverrides: {
        root: {  
          textTransform: 'capitalize',    
          fontStyle: 'italic',  
        }, 
      }   
    },
   
  },

  
});

export default theme;




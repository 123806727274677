import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import "../../../pages/admin/ImportReport.css"
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CSVReader from './CSVReader'
import Container from '@mui/material/Container'
import { getMonthYear, setMonthYear } from '../../../store/reportMonthYearSlice'
import { useSelector, useDispatch } from 'react-redux'
import CountryMenuItemList from './CountryMenuItemList'
import { getSelectedCountry } from '../../../store/countriesSlice'
import { getReport, setAdminUser } from '../../../store/csvReportSlice'
import { next } from '../../../store/importReportSlice'
import StatusTable from './StatusTable'
import { getUser} from '../../../store/userSlice'

const FirstStep = () => {
  const dispatch = useDispatch();
  const reportDate = useSelector(getMonthYear)
  const selectedcountry = useSelector(getSelectedCountry)
  const uploadedReport = useSelector(getReport)
  const user = useSelector(getUser);
  
 

  const isButtonDisabled = selectedcountry === "" || reportDate === undefined || uploadedReport === null;

  const handleDateChange = (newreportDate) => {
    if (newreportDate !== null) dispatch(setMonthYear(newreportDate.toDateString()))
    else dispatch(setMonthYear(new Date().toLocaleDateString()))
  };

  const handleNext = () => {
    if(reportDate === "") dispatch(setMonthYear(new Date().toLocaleDateString()));
    dispatch(setAdminUser(user.id));
    dispatch(next())
  }
  return (
    <>
    <Container maxWidth="sm">
      <Grid container spacing={3}>

        <Grid item xs={6}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <CountryMenuItemList />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['year', 'month']}
                fullWidth
                label="Year and Month"
                openTo='month'
                value={reportDate === "" ? new Date() : reportDate}
                minDate={new Date('2021-01-02')}
                maxDate={new Date()}
                onChange={(newreportDate) => {
                  handleDateChange(newreportDate)
                }}
                renderInput={(params) => <TextField {...params} helperText={null} name="reportDate" size="small" />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <CSVReader />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          disabled={isButtonDisabled}
          color="primary"
        // onClick={!isError() ? handleNext : () => null}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Container>
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8, pb: 22 }}>
          <StatusTable />
      </Box>
    </>
  )
}
export default FirstStep
import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import "../../../pages/admin/ImportReport.css"
import Container from '@mui/material/Container'
import { next, restart } from '../../../store/importReportSlice'
import HeaderMapper from './HeaderMapper'
import { useSelector, useDispatch } from 'react-redux'
import { getDbHeaders, mapMenuItems } from '../../../store/csvReportSlice'
import { resetReport } from '../../../store/csvReportSlice'

const SecondStep = () => {
  const dispatch = useDispatch();
  const dbHeaders = useSelector(getDbHeaders);

  const isButtonDisabled = dbHeaders.some(header => 
    header.id !== "BlobImageName"
    && header.id !== "UseDefaultImage"
    && header.id !== "UseDefaultImage"
    && header.id !== "OriginalImageURL" 
    && header.mappedCSVField === "--------- Select ---------");

  const handleNext = () => {
    dispatch(mapMenuItems())
    dispatch(next())
  }

  const handleCancel = () => {
    dispatch(resetReport())
    dispatch(restart())
  }

  return (

    <Container maxWidth="md">
       <Box sx={{ flexGrow: 1}}>
      <Grid container spacing={3}  sx={{ justifyContent: 'center' }}>
        <>
          <Grid item xs={6}>
            <Box sx={{ minWidth: 442 }}>
              <HeaderMapper />
            </Box>
          </Grid>
        </>
      </Grid>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 12 }}>
     
      <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}          
          color="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          disabled={isButtonDisabled}
          color="primary"
          // onClick={!isError() ? handleNext : () => null}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Container>
  )
}
export default SecondStep
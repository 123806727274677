import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
    useGridApiRef,
    DataGridPro
} from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { XDATA_GRID_PRO_LICENSE } from '../../shared/Constants'
import { useSelector, useDispatch } from 'react-redux'
import {  getBrandsFiltered, setMatchingBrands, updateBrand, setBrandIds } from '../../../store/csvReportSlice'
import {  
    getBrandsStatus, 
    getBrandsError, 
    fetchBrands, 
    getBrandOptions,   
    selectAllBrands, 
    getBrandByName,
} from '../../../store/brandsSlice'

LicenseInfo.setLicenseKey(
    XDATA_GRID_PRO_LICENSE
)

export default function BrandsMapper() {

    const [editRowsModel, setEditRowsModel] = React.useState({});
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const dispatch = useDispatch();
    const brandOptions = useSelector(getBrandOptions);
    const brandsFiltered = useSelector(getBrandsFiltered);
    const brandsStatus = useSelector(getBrandsStatus);
    const brandsError = useSelector(getBrandsError);
    const allBrands = useSelector(selectAllBrands)

    useEffect(() => {
        if (brandsStatus === 'idle') {
            dispatch(fetchBrands())          
        }
    }, [brandsStatus, dispatch])

    dispatch(setMatchingBrands(allBrands))

    dispatch(setBrandIds(allBrands))

    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const apiRef = useGridApiRef();

    const handleCellClick = React.useCallback(
        (params) => {
          if(params.isEditable) apiRef.current.setCellMode(params.id, params.field, "edit");
        },
        [apiRef]
      );

      React.useEffect(() => {
        return apiRef.current.subscribeEvent(
          "cellModeChange",
          (params, event) => {
            event.defaultMuiPrevented = true;
          },
          { isFirst: true }
        );
      }, []);
    

    const handleRowEditCommit = React.useCallback(
        async (id) => {
            const model = apiRef.current.getEditRowsModel();
            const newRow = model[id];
            const mappedName = newRow.mappedCSVName.value;
            const oldRow = apiRef.current.getRow(id);

           try {   
            const b = dispatch(getBrandByName({ brandName: mappedName }));            
            dispatch(
                updateBrand({ id: id, mappedCSVName: mappedName, brandId: b.payload.id  })
              );     
                
            } catch (error) {
                console.log(error);
                apiRef.current.updateRows([oldRow]);
                setSnackbar({ children: 'Error while saving record. See log for details.', severity: 'error' });
            }
        },
        [apiRef],
    );

    const columns = [
        {
            field: 'brandName',
            headerName: 'Brand Names in CSV',
            minWidth: 220,
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            headerClassName: 'grid-theme--header',
        },
        {
            field: 'mappedCSVName',
            headerName: 'Database Options',
            minWidth: 220,
            editable: true,
            type: 'singleSelect',
            disableColumnMenu: true,
            sortable: false,
            headerClassName: 'grid-theme--header',
            preProcessEditCellProps: (params) => {   
                const isValid = params.props.value !== '--------- Select ---------' ? true : false;
                return { ...params.props, error: !isValid };
              },
           valueOptions: ['--------- Select ---------', ...brandOptions]
        },

    ];

    return (
        <>           
            <Box
                sx={{
                    width: '100%',
                                      

                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        color: '#000000',
                        fontWeight: 600,
                        fontSize: '14px'
                    },
                    '& .MuiDataGrid-cell--editing': {
                        '& .MuiInputBase-root': {
                            height: '100%',
                        },
                    },
                    '& .Mui-error': {
                        bgcolor: (theme) =>
                            `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
                        color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
                    },
                    '& .MuiGrid-root.MuiGrid-container': {
                        justifyContent: 'center',
                    },
                    '& .cell-bg.needtoedit': {
                        backgroundColor: '#ecd291',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#fafafa',
                    },
                    '& .grid-theme--header': {
                        backgroundColor: '#fafafa',
                    }
                }}
            >

                <DataGridPro
                    sx={{
                        height: 542,
                        width: 470,
                    }}
                    rows={brandsFiltered}
                    editMode="row"
                    columns={columns}
                    apiRef={apiRef}
                    onRowEditCommit={handleRowEditCommit}
                    disableColumnPinning={true}
                    hideFooter={true}
                    hideFooterPagination={true}
                    hideFooterRowCount={true}
                    hideFooterSelectedRowCount={true}
                    loading={brandsFiltered.length === 0}
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    onCellClick={handleCellClick}
                />
                {!!snackbar && (
                    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}

            </Box>
        </>
    );
}

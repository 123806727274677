import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns'

export function MenuItemHit({ hit, presentationMenuItems, togglePresentationMenuItems }) {
    const checked = presentationMenuItems && presentationMenuItems.includes(hit.id);
    const launchDate = new Date(hit.date_timestamp * 1000);

    return (
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            borderTop: '2px solid #afafaf',
            pt: 2,
            pb: 2,
        }} className="menu-item-row" key={hit.id}>

            <FormControlLabel className="checkbox-label" sx={{ mr: 0 }}
                control={
                    <Checkbox />}
                label=""
                title={''}
                checked={checked}
                onChange={(event) => togglePresentationMenuItems([hit.id], event.target.checked)}
                aria-describedby={hit.id}
            />

            <img className="menu-item-row--menu-thumbnail" src={hit.menuItemThumbnail} />

            <Box className="menu-item-row--details"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                <Typography gutterBottom variant="h4" component="div" sx={{ mt: 1, mr: 1, mb: 0 }}>
                    <em>{hit.menuItemName}</em>
                </Typography>

                <Box sx={{
                    flexDirection: 'row',
                    display: 'flex'
                }}>
                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                        <b>Brand:</b>
                        {(() => {
                            if (hit.brand.brandLogoThumbnail !== undefined && hit.brand.brandLogoThumbnail !== '') {
                                return <img className="menu-item-row--brand-thumbnail" src={hit.brand.brandLogoThumbnail} style={{ marginBottom: '-10px' }} />;
                            }
                            // } else {
                            //     return <img className="menu-item-row--brand-thumbnail" src="/images/blank.png"/>;
                            // }
                        })()}
                        &nbsp;{hit.brand.brandName}
                        &nbsp;&nbsp; | &nbsp;&nbsp;<b>Meal Part:</b> {hit.mealpart}
                        &nbsp;&nbsp; | &nbsp;&nbsp;<b>Day Part:</b> {hit.daypart}
                        &nbsp;&nbsp; | &nbsp;&nbsp;<b>Country:</b>
                        <img className="menu-item-row--flag-thumbnail" src={hit.country.countryFlagIcon} style={{ marginBottom: '-10px' }} />
                        {hit.country.countryCode}
                        &nbsp;&nbsp; |  &nbsp;&nbsp;<b>Launched:</b> {format(launchDate, 'MMMM')} {format(launchDate, 'yyyy')}
                    </Typography>

                </Box>

                <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                    {hit.description}
                </Typography>
            </Box>
        </Box>
    );
}

MenuItemHit.propTypes = {
  hit: PropTypes.object.isRequired,
};

import React from 'react';
import DaypartsMapper from './DaypartsMapper';
import MealpartsMapper from './MealpartsMappper';
import BrandsMapper from './BrandsMapper';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function MenuItemMapper() {
    return (
        <Box sx={{ flexGrow: 1 }}>
           <Grid container spacing={5} sx={{ justifyContent: 'center' }}>
            <Grid item xs={5}>
                   <DaypartsMapper />
                </Grid>
                <Grid item xs={5}>
                    <MealpartsMapper />
                </Grid>
                <Grid item xs={5}>
                    <BrandsMapper />
                </Grid>
            </Grid>
        </Box>



    );
}

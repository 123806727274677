import React, { useEffect } from 'react'
import "../../../pages/admin/ImportReport.css"
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { useSelector, useDispatch } from 'react-redux'
import { restart } from '../../../store/importReportSlice'
import { getSelectedCountry } from '../../../store/countriesSlice'
import {
  resetReport,
  getCSVFileName,
  getMenuItems,
  setDayPartIds,
  setMealPartIds,
  setBrandIds,
  importReport,
  createMenuGroup,
  getReportForImport,
  getCSVImportStatus,
  getCSVImportError,
} from '../../../store/csvReportSlice'

import { getMonthYear } from '../../../store/reportMonthYearSlice'
import { format } from 'date-fns'
import {
  DataGridPro
} from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { XDATA_GRID_PRO_LICENSE } from '../../shared/Constants'
import {
  getDaypartsStatus,
  getDaypartsError,
  fetchDayparts,
  getAllDayparts,
} from '../../../store/daypartsSlice'
import {
  getMealpartsStatus,
  getMealpartsError,
  fetchMealparts,
  selectAllMealparts,
} from '../../../store/mealpartsSlice'
import {
  getBrandsStatus,
  getBrandsError,
  fetchBrands,
  selectAllBrands,
} from '../../../store/brandsSlice'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { unwrapResult } from '@reduxjs/toolkit'

LicenseInfo.setLicenseKey(
  XDATA_GRID_PRO_LICENSE
)

export default function Confirm() {
  const dispatch = useDispatch();
  const selectedcountry = useSelector(getSelectedCountry)
  const reportDate = useSelector(getMonthYear)
  const csvFileName = useSelector(getCSVFileName)
  const menuitems = useSelector(getMenuItems)
  const dayparts = useSelector(getAllDayparts)
  const csvImportStatus = useSelector(getCSVImportStatus);
  const csvImportError = useSelector(getCSVImportError);
  const mealpartsStatus = useSelector(getMealpartsStatus);
  const mealpartsError = useSelector(getMealpartsError);
  const mealparts = useSelector(selectAllMealparts)
  const brandsStatus = useSelector(getBrandsStatus);
  const brandsError = useSelector(getBrandsError);
  const brands = useSelector(selectAllBrands)
  const daypartsStatus = useSelector(getDaypartsStatus);
  const daypartsError = useSelector(getDaypartsError);
  const finalReport = useSelector(getReportForImport)
var isButtonDisabled = false
  const handleNext = () => {
    isButtonDisabled = true
    
      dispatch(createMenuGroup(...finalReport))
        .then(unwrapResult).then(originalPromiseResult => {
          dispatch(resetReport())
          dispatch(restart()) 
          
        })
        .catch(rejectedValueOrSerializedError => {
          setSnackbar({ children: `An error has occured saving record. Check log for details.`, severity: 'error' });
        })
                      
  }
    
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const handleCancel = () => {
    dispatch(resetReport())
    dispatch(restart())
  }

  useEffect(() => {
    if (daypartsStatus === 'idle') {
      dispatch(fetchDayparts())
    }
  }, [daypartsStatus, dispatch])

  dispatch(setDayPartIds(dayparts))

  useEffect(() => {
    if (mealpartsStatus === 'idle') {
      dispatch(fetchMealparts())
    }
  }, [mealpartsStatus, dispatch])

  dispatch(setMealPartIds(mealparts))

  useEffect(() => {
    if (brandsStatus === 'idle') {
      dispatch(fetchBrands())
    }
  }, [brandsStatus, dispatch])

  dispatch(setBrandIds(brands))

  const columns = [
    {
      field: 'dayPartName',
      headerName: 'Day Part',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      // sortable: false,
      headerClassName: 'grid-theme--header',
    },
    {
      field: 'mealPartName',
      headerName: 'Meal Part',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      //  sortable: false,
      headerClassName: 'grid-theme--header',
    },
    {
      field: 'brandName',
      headerName: 'Brand',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      //  sortable: false,
      headerClassName: 'grid-theme--header',
    },
    {
      field: 'menuItemName',
      headerName: 'Menu Item Name',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      //  sortable: false,
      headerClassName: 'grid-theme--header',
    },
    ,
    {
      field: 'description',
      headerName: 'Menu Item Description',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      //  sortable: false,
      headerClassName: 'grid-theme--header',
    },
    {
      field: 'originalImageUrl',
      headerName: 'Link',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      //  sortable: false,
      headerClassName: 'grid-theme--header',
    },
    {
      field: 'blobImageName',
      headerName: 'Image File',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      //  sortable: false,
      headerClassName: 'grid-theme--header',
    },
    ,
    {
      field: 'useDefaultImage',
      headerName: 'Default Image',
      minWidth: 150,
      editable: false,
      // disableColumnMenu: true,
      //  sortable: false,
      headerClassName: 'grid-theme--header',
    },
  ];

  return (
    <>

      <Divider />

      <p>Confirm</p>
      <p>Country: {selectedcountry.countryLongName}</p>
      <p>Report Period: {format(new Date(reportDate), 'MMMM yyyy')}</p>
      <p>Report File: {csvFileName}</p>
      <Divider />


      <Box
        sx={{
          width: '100%',
          marginTop: 5,

          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#000000',
            fontWeight: 600,
            fontSize: '14px'
          },
          '& .MuiDataGrid-cell--editing': {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
          '& .Mui-error': {
            bgcolor: (theme) =>
              `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
            color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
          },
          '& .MuiGrid-root.MuiGrid-container': {
            justifyContent: 'center',
          },
          '& .cell-bg.needtoedit': {
            backgroundColor: '#ecd291',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#fafafa',
          },
          '& .grid-theme--header': {
            backgroundColor: '#fafafa',
          }
        }}
      >

        <DataGridPro
          sx={{
            height: 600,
          }}
          rows={menuitems}
          columns={columns}
          disableColumnPinning={true}
          hideFooter={true}
          hideFooterPagination={true}
          hideFooterRowCount={true}
          hideFooterSelectedRowCount={true}
          loading={menuitems.length === 0}
        />
        {!!snackbar && (
          <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>


      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, pb: 5, }}>
        <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          color="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          color="primary" onClick={handleNext}>
          Confirm & Import Report
        </Button>
      </Box>
    </>
  )
}

import React, { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box';
import { format } from 'date-fns'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';


import {
    selectDefaultMenuGroups,
    getMenuGroupStatus,
    getMenuGroupError,
    fetchImportPreview,
    
} from '../../store/browseMenuItemsSlice'
import { useHistory, useParams } from 'react-router-dom'

import { BRANDS_URL_PREFIX, FLAG_URL_PREFIX, SCREENGRAB_URL_PREFIX, MENU_URL_PREFIX } from "../../components/shared/Constants";

export default function ReviewReport() {

    const { groupId } = useParams()

    const menuitemgroups = useSelector(selectDefaultMenuGroups);
    const menugroupStatus = useSelector(getMenuGroupStatus);
    const menugroupError = useSelector(getMenuGroupError);

    const dispatch = useDispatch();
    useEffect(() => {
        if (menugroupStatus === 'idle') {
            try {
                dispatch(fetchImportPreview(groupId))
            } catch (err) {
                console.log(err)
            }
        }

    }, [menugroupStatus, dispatch])


    return (

        <>
          
            {menuitemgroups.map(group => (

                <Container
                    sx={{
                        maxWidth: "1340px",
                        display: 'flex',
                        flexDirection: 'column',
                        mt: 2,
                        mb: 2,
                    }} fixed>
                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                        GROUP ID:   {group.id}
                    </Typography>
                    {group.menuItems?.map(item => (
                        <Box id={group.id} sx={{
                            alignItems: 'center',
                            display: 'flex',
                            borderTop: '2px solid #afafaf',
                            pt: 2,
                            pb: 2,
                        }} className="menu-item-row" key={item.id}>

                           
                            {(() => {
                                if (item.blobImageName !== undefined && item.blobImageName !== '') {
                                    return (<img className="menu-item-row--menu-thumbnail" src={MENU_URL_PREFIX
                                        + format(new Date(group.dateForRange), 'yyyy') + '/'
                                        + format(new Date(group.dateForRange), 'MMM').toLowerCase() + '/'
                                        + (group.countryNavigation.countryShortName).replace(/\s/g, "-").toLowerCase()
                                        + '/' + item.blobImageName} />)
                                } else if (item.useDefaultImage === "yes" || item.useDefaultImage === "1") {
                                    return (<img className="menu-item-row--menu-thumbnail" src="/images/default-ppt.png" />)

                                } else if (item.screenshot !== undefined && item.screenshot !== '') {
                                    //screengrab 
                                    return (<img className="menu-item-row--menu-thumbnail" src={SCREENGRAB_URL_PREFIX
                                        + format(new Date(group.dateForRange), 'yyyy') + '/'
                                        + format(new Date(group.dateForRange), 'MMM').toLowerCase() + '/'
                                        + (group.country)
                                        + '/' + item.screenshot} />)
                                } else {
                                    return (<img className="menu-item-row--menu-thumbnail" src="/images/default-ppt.png" />)

                                }
                            })()}

                            <Box className="menu-item-row--details"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}>
                                <Typography gutterBottom variant="h4" component="div" sx={{ mt: 1, mr: 1, mb: 0 }}>
                                    <em>{item.menuItemName}</em>
                                </Typography>

                                <Box sx={{
                                    flexDirection: 'row',
                                    display: 'flex'
                                }}>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                        Brand:
                                    </Typography>
                                    {(() => {
                                        if (item.brandNavigation.brandLogo !== undefined && item.brandNavigation.brandLogo !== null && item.brandNavigation.brandLogo !== '') {
                                            return <img className="menu-item-row--brand-thumbnail" src={BRANDS_URL_PREFIX +
                                                item.brandNavigation.brandLogo} />;
                                        }
                                        // } else {
                                        //     return <img className="menu-item-row--brand-thumbnail" src="/images/blank.png"/>;
                                        // }
                                    })()}

                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                        &nbsp;{item.brandNavigation.brandName}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                        | &nbsp;&nbsp;Meal Part: {item.mealPartNavigation.mealPartName}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                        | &nbsp;&nbsp;Day Part: {item.dayPartNavigation.dayPartName}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                        | &nbsp;&nbsp;Country:
                                    </Typography>
                                    <img className="menu-item-row--flag-thumbnail" src={FLAG_URL_PREFIX + group.countryNavigation.countryFlagImage} />
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                        {group.countryNavigation.countryShortName}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1 }}>
                                        |  &nbsp;&nbsp;Launched: {format(new Date(group.dateForRange), 'MMMM')}
                                        {format(new Date(group.dateForRange), 'yyyy')}
                                    </Typography>

                                </Box>

                                <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                    {item.description}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Container>
            ))}
        </>
    )
}
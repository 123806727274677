import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


export default function Footer() {
  return (
    <footer>
      <div className="footer-left">
        <Typography variant="subtitle1" sx={{ mt: 5, mb: 5 }}>©2024 Griffith Foods</Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, fontSize: "0.7rem !important" }}><Link href="/admin/import-report">Admin Login</Link></Typography>
      </div>
      <div className="footer-right">
        <Typography variant="subtitle1" sx={{ mt: 5, mb: 5, textAlign: 'right' }}>
          <Link target="_blank" href="https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fgriffithfoods.service-now.com%2F%24pwd_reset.do%3Fsysparm_url%3DAD&data=01%7C01%7Ccevans%40griffithfoods.com%7C4c853a09a259492a967808d83f0c1071%7C16a486f632144c80b38d52f754d87bbb%7C0&sdata=NUitCec95BGPxHg418%2BJonkh1%2FiolhsWRd0bRCtZzs0%3D&reserved=0">Griffith Employees</Link>&nbsp;&nbsp; |
          &nbsp;&nbsp;<Link target="_blank" href="https://griffithfoods.com/privacy-policy/">Griffith Foods™ Privacy Policy</Link>&nbsp;&nbsp;  |
          &nbsp;&nbsp;<Link target="_blank" href="https://griffithfoods.com/terms/">Terms of Use Agreement</Link>
        </Typography>
      </div>
    </footer>
  )
}


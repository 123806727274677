import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React from "react";
import create from "zustand";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

type ConfirmDialogStore = {
    message: string;
    onSubmit?: () => void;
    close: () => void;
}

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
    message: '',
    onSubmit: undefined,
    close: () =>
        set({
            onSubmit: undefined,
        }),
    }));

  export const confirmDialog = (message: string, onSubmit: () => void) =>
  {   
    useConfirmDialogStore.setState({
        message,
        onSubmit 
    });
  }
   
  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const ConfirmDialog:React.FC = () => {
    const { message, onSubmit, close } = useConfirmDialogStore();
    return(
        <>
         <Dialog
            open={Boolean(onSubmit)} 
            onClose={close}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
          <WarningAmberOutlinedIcon color="warning"/> &nbsp;&nbsp;<Typography>Please Confirm</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close} color="primary">
            Cancel
          </Button>
          <Button color="primary"
                onClick={() => {
                    if(onSubmit) {
                        onSubmit();
                    }                    
                    close();
                }}>Delete</Button>
        </DialogActions>
      </Dialog>


        </>
    )
};

export default ConfirmDialog;


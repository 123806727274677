import React, { useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { selectAllCountries, getCountriesStatus, getCountriesError, setSelectedCountry, fetchCountries } from '../../../store/countriesSlice'
import { useSelector, useDispatch } from 'react-redux'
import { FLAG_URL_PREFIX } from '../../shared/Constants'
import TextField from '@mui/material/TextField'

const CountryMenuItemList = () => {

    const dispatch = useDispatch();
    const countries = useSelector(selectAllCountries);
    const countriesStatus = useSelector(getCountriesStatus);
    const error = useSelector(getCountriesError);

    const [countryCode, setCountryCode] = React.useState('');
    
    useEffect(() => {
        if (countriesStatus === 'idle') {
            dispatch(fetchCountries())
        }
    }, [countriesStatus, dispatch])

    const handleCountryChange = event => {
        setCountryCode(event.target.value)
        dispatch(setSelectedCountry(event.target.value))
    };

    return (
        <>

         <TextField
            select
            id="country"
            label="Select Market"
            onChange={handleCountryChange}
            size="small"
            fullWidth
            className="country-select"
            value={countryCode}
        >
            
            {countries.map(country => (
                <MenuItem value={country.countryCode} key={country.countryCode} name={country.countryLongName}>
                    <img className="select-icon" src={FLAG_URL_PREFIX + country.countryFlagImage} />{" "}
                    {country.countryLongName}
                </MenuItem>
            ))}

        </TextField> 

        </>
      
    )
}

export default CountryMenuItemList;
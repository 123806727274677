import React from 'react';
import { Outlet } from "react-router-dom"
import PrimaryNavTabBar from '../components/navbar/PrimaryNavTabBar';

export default function DashboardLayout({ children }) {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="content">
          <div className="container">
            <PrimaryNavTabBar />
          </div>
          <Outlet />
        </div>
      </div>

    </div>
  )
}
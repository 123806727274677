import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import "./MenuItems.css";
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import CardActions from '@mui/material/CardActions';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { format } from 'date-fns'
import { selectSavedSearches, fetchSavedSearches, updateSavedSearchName } from '../store/savedSearchesSlice'
import { BRANDS_URL_PREFIX, FLAG_URL_PREFIX, SCREENGRAB_URL_PREFIX, MENU_URL_PREFIX } from '../components/shared/Constants'
import { useGridSelector } from '@mui/x-data-grid-pro';
import { getUser} from '../store/userSlice'

const SavedSearches = () => {
    const [country, setCountry] = React.useState('US');
    const [countryList, setCountryList] = React.useState([]);
    const now = new Date();
    const [fromDate, setFromDate] = React.useState(new Date(2022, 5, 1));
    const [toDate, setToDate] = React.useState(new Date(now.getFullYear(), now.getMonth(), 1));
    const [keyword, setKeyword] = React.useState('');
    const [savedSearchName, setSavedSearchName] = React.useState('');
    const [savedSearchId, setSavedSearchId] = React.useState(0);
    const [checked, setChecked] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const [openModal, setOpenModal] = React.useState(false);
    const dispatch = useDispatch();
    const savedSearches = useSelector(selectSavedSearches)
    const user = useSelector(getUser);

    const [snackbar, setSnackbar] = React.useState(null);

    useEffect(() => {
        dispatch(fetchSavedSearches({ data: { staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword } }))
    }, [keyword, fromDate, toDate, dispatch])

    const valueRef = useRef(false)

    const sendValue = () => {
        return console.log(valueRef.current.value) //on clicking button accesing current value of TextField and outputing it to console 
    }


    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    function editSavedSearch(id, name) {
        setSavedSearchId(id);
        setSavedSearchName(name);
        handleOpen(true);

    }
    function saveName() {
        dispatch(updateSavedSearchName({ savedSearchName, savedSearchId })).then(() => {
            setSnackbar({ children: 'Search Updated successfully', severity: 'success' });
        });
        dispatch(fetchSavedSearches({ data: { staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword } }));
        handleClose(true);
    }




    const useStyles = makeStyles(theme => ({
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        menuPaper: {
            maxHeight: 300
        }

    }));

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [savedSearchesToExport, setSavedSearchesToExport] = useState([]);

    const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%,-50%)',
        width: 550,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        maxHeight: '80%',
        overflowY: 'scroll',
        overflowX: 'none',
        p: 2,
        pr: 1
    };


    return (

        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Typography>
                        Edit <TextField

                            fullWidth
                            id="search-input"
                            size="small"
                            placeholder={savedSearchName}
                            InputLabelProps={{ shrink: true }}
                            value={savedSearchName}
                            onChange={event => {
                                const { value } = event.target;
                                setSavedSearchName(value);
                            }}
                        />
                    </Typography>
                    
                    <CardActions>
                        <Button variant="contained" color="success"
                            onClick={() => {
                                saveName()
                            }}
                        >Save</Button>
                    </CardActions>
                </Box>

            </Modal>
            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2
                }} fixed>

                <FormControl sx={{ m: 1 }} variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}
                            label="Date From"
                            value={fromDate}
                            minDate={new Date('2021-01-02')}
                            maxDate={new Date()}
                            onChange={(newFromDate) => {
                                setFromDate(newFromDate);
                            }}
                            openTo='month'
                            disableCloseOnSelect={false}
                            renderInput={(params) =>
                                <TextField {...params} className="date-picker" helperText={null} name="fromDate" size="small" />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}

                            label="Date To"
                            openTo='month'
                            value={toDate}
                            minDate={new Date('2021-01-02')}
                            maxDate={new Date(Date.now())}
                            disableCloseOnSelect={false}
                            onChange={(newToDate) => {
                                setToDate(newToDate);
                            }}
                            renderInput={(params) => <TextField {...params} className="date-picker" helperText={null} name="toDate" size="small" />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1, width: '500px' }} variant="outlined">
                    <TextField
                        label="Keyword Search"
                        type="search"
                        fullWidth
                        id="search-input"
                        size="small"
                        placeholder='Search through your presentations'
                        InputLabelProps={{ shrink: true }}
                        value={keyword}
                        onChange={event => {
                            const { value } = event.target;
                            setKeyword(value);
                        }}
                        renderInput={(params) => <TextField {...params} className="text" helperText={'Search through your presentations'} name="keyword" size="small" />}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchIcon className='search-icon' /></InputAdornment>,
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '135px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Search
                    </Button>
                </FormControl>

            </Container>

            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2,
                    justifyContent: 'space-between',
                    backgroundColor: '#f7f7f7',
                    pt: 1,
                    pb: 1
                }} fixed>
                <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                    <em>My Saved Searches:</em>
                </Typography>

                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}>
                    <DeleteIcon fontSize="small" sx={{ mr: 1 }} />     {"  "}
                    <Button variant="body2"
                        onClick={() => {
                            try {
                                const response = axios.post('api/SavedSearch/DeleteSavedSearch', savedSearchesToExport, {
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                    },
                                })
                                    .then(function (results) {
                                        dispatch(fetchSavedSearches({ data: { staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword } }))
                                    })
                                   
                                return [...response.data];
                            } catch (err) {
                                return err.message;
                            }
                        }}
                    >
                        {' Delete Selected'}
                    </Button>
                </Box>

            </Container>

            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2,
                    justifyContent: 'space-between',
                }} fixed>




            </Container>

            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 2,
                    mb: 2,
                }} fixed>

                {savedSearches?.map((savedSearch) => {
                    var filters = JSON.parse(savedSearch.searchFilters);
                    var queryStr = '';
                    if ('query' in filters && filters.query && filters.query.length > 0)
                        queryStr = filters.query;
                    else
                        queryStr = 'NO TEXT SEARCHED';
                    var filterContentStr = '';
                    var displayFromDate = new Date(filters.fromDate);
                    displayFromDate.setUTCSeconds(filters.fromDate);
                    var displayToDate = new Date(0);
                    displayToDate.setUTCSeconds(filters.toDate);
                    if (('fromDate' in filters && (filters.fromDate && filters.fromDate > 0)) && ('fromDate' in filters && (filters.fromDate && filters.fromDate > 0)))
                        filterContentStr += 'Date Range: ' + format(displayFromDate, 'MMM. yyyy') + ' - ' + format(displayToDate, 'MMM. yyyy') + ' | ';
                    if ('mealpart' in filters && filters.mealpart && filters.mealpart.length > 0)
                        filterContentStr += 'Meal Part: ' + filters.mealpart + ' | ';
                    if ('daypart' in filters && filters.daypart && filters.daypart.length > 0)
                        filterContentStr += 'Day Part: ' + filters.daypart + ' | ';
                    if ('country' in filters && filters.country && filters.country.length > 0)
                        filterContentStr += 'Country: ' + filters.country + ' | ';
                    if ('brand' in filters && filters.brand && filters.brand.length > 0)
                        filterContentStr += 'Brand: ' + filters.brand + ' | ';

                    
                    return (
                        
                        <Box id={savedSearch.id} sx={{
                            alignItems: 'center',
                            display: 'flex',
                            borderTop: '2px solid #afafaf',
                            pt: 2,
                            pb: 2,
                        }} className="menu-item-row" key={savedSearch.id}>
                            
                            <FormControlLabel className="checkbox-label" sx={{ mr: 0 }}
                                control={
                                    <Checkbox />}
                                label=""
                                checked={valueRef.checked}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSavedSearchesToExport([
                                            ...savedSearchesToExport,
                                            {
                                                id: savedSearch.id
                                            },
                                        ]);
                                    } else {
                                        setSavedSearchesToExport(
                                            savedSearchesToExport.filter(present => present.id !== savedSearch.id),
                                        );
                                    }
                                }}
                                aria-describedby={savedSearch.id}
                                inputRef={valueRef}
                            />
                            <Box className="menu-item-row--details"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '90%',
                                    ml: 3
                                }}>
                                <Typography gutterBottom variant="h4" component="div" sx={{ mt: 1, mr: 1, mb: 0 }}>
                                    <em><Link to={{ pathname: "/browse-menu-items", search: "?search=" + savedSearch.id }} style={{ color: '#007044', textDecoration: 'none' }}>{savedSearch.searchName}</Link></em>
                                    <IconButton  variant="contained" sx={{ color:'#000' }} color="neutral" onClick={() => {
                                        editSavedSearch(savedSearch.id, savedSearch.searchName);
                                    }}><EditIcon /></IconButton>
                                    
                                </Typography>
                                <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1, mb: 0 }}>
                                    <em>Search Saved on: {format(new Date(savedSearch.searchSavedDate),'PPPpp')}</em>

                                </Typography>
                                
                                <Typography color="black" gutterBottom variant="h6" component="div" sx={{ mt: 1, mr: 1, mb: 0 }}>
                                    <em>Search Parameters Used:</em>

                                </Typography>

                                <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1, mb: 0 }}>
                                    <em>{filterContentStr.slice(0,filterContentStr.length -2)}</em>
                                    
                                </Typography>
                                
                                <Box sx={{
                                    flexDirection: 'column',
                                    display: 'flex'
                                }}>
                                    


                                </Box>
                            </Box>

                        </Box>

                    )
                })}
            </Container>
            {!!snackbar && (
                <Snackbar open onClose={() => setSnackbar(null)} autoHideDuration={5000}>
                    <Alert {...snackbar} onClose={() => setSnackbar(null)} />
                </Snackbar>
            )}
        </>
    );
}
export default SavedSearches
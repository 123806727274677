import React, { useEffect, useState } from "react";
import DayPartsManager from "../../components/admin/menu-filters/DayPartsManager";
import MealPartsManager from "../../components/admin/menu-filters/MealPartsManager";
import BrandsManager from "../../components/admin/menu-filters/BrandsManager";
import styled from '@emotion/styled';
import "./ManageMenuFilters.css"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

export default function ManageMenuFilters() {


  return (

    <main>
      <Container maxWidth="xl" sx={{ paddingBottom: 25 }}>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" align="center">
            Manage Menu Filters
          </Typography>
        </Box>
        
        <Box sx={{ flexGrow: 1}}>
            <Grid container spacing={5} sx={{ justifyContent: 'center' }}>
                <Grid item xs={4}>
                <Typography gutterBottom variant="h6" align="center">Day Parts</Typography>
            <DayPartsManager />
                </Grid>
                <Grid item xs={4}>
                <Typography gutterBottom variant="h6" align="center">Meal Parts</Typography>
            <MealPartsManager />
                </Grid>
                <Grid item xs={7}>
                <Typography gutterBottom variant="h6"align="center">Brands</Typography>
            <BrandsManager />
                </Grid>
            </Grid>
        </Box>

        {/* <div className="filter-managers">
          <div className="filter">
            <Typography gutterBottom variant="h6" align="center">Day Parts</Typography>
            <DayPartsManager />
          </div>
          <div className="filter">
            <Typography gutterBottom variant="h6" align="center">Meal Parts</Typography>
            <MealPartsManager />
          </div>
          <div className="filter">
            <Typography gutterBottom variant="h6"align="center">Brands</Typography>
            <BrandsManager />
          </div>
        </div> */}

      </Container>
    </main>

  );


}



